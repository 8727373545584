/* :root {
  --form-bg-color: transparent;
} */

.form {
  position: relative;
  padding: 0 20px;
  margin-bottom: 5em;
  /* background-color: var(--form-bg-color); */
  background-size: cover;
  /* background-image: url(../images/DSC00838.jpg); */
  background-position: center;
  background-size: cover;

  --border-color: rgba(var(--font-color-rgb), 0.2);
}

.form__content {
  position: relative;
  z-index: 2;
  max-width: 400px;
  margin: 0 auto;
  padding-bottom: 5px;
}

.form__loading {
  display: none;
}

.form__content--isSuccess {
  text-align: center;
  font-size: 2em;
}

.form__content--isSubmitting .form__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  height: 100%;
  z-index: 3;
  background-color: rgba(250, 250, 250, 0.8);
}

.form-field {
  margin-bottom: 1em;
}

.form-field label {
  display: block;
  margin-bottom: 0.3em;
}

.form select {
  display: block;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  /* color: var(--form-bg-color); */
}

.form textarea {
  display: block;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  min-height: 100px;
  /* color: var(--form-bg-color); */
}

.btn {
  display: block;
  padding: 0.5em 1em;
  margin: 0 auto;
  border: none;
  font-size: 1.2em;
  border-radius: 3px;
}

.btn--primary {
  color: var(--bg-color);
  background-color: var(--secondary-color);
}

.btn--secondary {
  color: var(--font-color);
  background-color: var(--bg-color);
  border: 1px solid;
}

.form__msg {
  display: block;
  margin-top: 0.3em;
}

.form__msg--error {
  color: var(--error-color);
  text-align: center;
}
