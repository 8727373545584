:root {
  /*
    This will enable using this variable as rgba(var(--font-color-rgb), 0.2)
    to apply an alpha to it 
  */
  --font-color-rgb: 73, 73, 93;
  --font-color: rgb(var(--font-color-rgb));
  --secondary-color: #8d3850;
  --bg-color: #fafafa;
  --error-color: #ff2c46;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
}

body {
  padding: 2em 1em;
  background-color: var(--bg-color);
  font-family: 'Roboto', 'sans-serif';
  color: var(--font-color);
}

#root {
  /* width: 800px; */
  max-width: 900px;
  margin: 0 auto;
}

img {
  max-width: 100%;
}

@media screen and (min-width: 900px) {
  body {
    padding-top: 5em;
    padding-bottom: 5em;
  }
}
