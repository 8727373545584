
.hero {
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto 2em;
}

.hero__img {
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.hero__img img {
  position: absolute;
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 900px) {
  .hero {
    margin-bottom: 4em;
  }
}
