.info {
  
  width: fit-content;
  font-family: 'Josefin Slab';
  padding: 0 1em;
  line-height: 1.3;
  font-size: 1.5em;
  margin: 0 auto;
  text-align: center;

  --big-padding: 30px;
}

.info small {
  display: block;
}



.info__panel--left {
  justify-content: flex-end;
  padding: 0 0 10px 0;
  border-bottom: 1px solid var(--font-color);
}

.info__panel--right {
  padding: 10px 0 0;
  justify-content: flex-start;
}

@media screen and (min-width: 900px) {
  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5em;
  }

  .info__panel {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    border-bottom: none;
    width: 315px;
  }
  .info__panel--left {
    text-align: right;
    padding: 20px var(--big-padding) 20px 0;
    border-right: 1px solid var(--font-color);

  }
  .info__panel--right {
    text-align: left;
    padding: 20px 0 20px var(--big-padding);
  }
}
