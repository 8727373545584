.intro {
  margin: 0 auto;
  padding: 3em 10px;
  max-width: 500px;
}

.intro__text {
  text-align: center;
}

.intro__text h2 {
  font-family: 'Parisienne', cursive;
  font-size: 2.5em;
  margin: 0;
  font-weight: 300;
  /* color: #444040; */
}
